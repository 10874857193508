import { Props } from "lib/data";
import { useEffect, useRef, useState } from "react";
import useAppStore from "store/appstore";

const TemplatePicture: React.FC<Props> = ({ mediaURL }) => {
  const changePage = useAppStore((state) => state.changePage);
  const [cachedURL, setCachedURL] = useState<string | undefined>(undefined);
  let getMediaFromCache = useAppStore((state) => state.getMediaFromCache);
  const timeout = useRef<any | null>(null);

  useEffect(() => {
    timeout.current = setTimeout(changePage, 10000);
    getMediaFromCache(mediaURL!).then((url) => {
      if (!url) {
        console.log("missing cachedURL");
        clearInterval(timeout.current);
        changePage();
      } else {
        console.log(`set cached url ${url}`);
        setCachedURL(url!);
      }
    });
  }, []);
  return <div>{cachedURL && <img src={cachedURL} alt="" />}</div>;
};
export default TemplatePicture;
