import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import BackgroundView from "../components/BackgroundView";
import MatchView from "../components/MatchView";
import NewMatchView from "components/NewMatchView";
import NewBackgroundView from "components/NewBackgroundView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

/**
 * Template 18 - Top 10
 * @param props
 */
const TemplateTop10: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <div>
      <NewBackgroundView
        background={template.background}
        title={template.betModel.title}
        subTitle={template.betModel.subTitle}
        titleHighlight={template.betModel.titleHighlight}
        subTitleHighlight={template.betModel.subTitleHighlight}
        altLogo={true}
        titleSmaller={true}
      >
        <div className="relative flex flex-col justify-center w-[1920px] mt-16 tall:mt-32">
          {template.betModel.matches!!.map((element, index) => {
            return (
              <div
                key={index}
                className="block mx-[94px] h-[65px] animate-fade-in-left-match tall:mt-8 my-[2px]"
                style={{ animationDuration: `${1800 + 100 * index}ms` }}
              >
                <NewMatchView
                  key={index}
                  match={element}
                  timeBeforeOdds={true}
                  className={"Match-smallest"}
                />
              </div>
            );
          })}
        </div>
      </NewBackgroundView>
    </div>
  );
};

export default TemplateTop10;
